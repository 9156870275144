.contact-section-container {
  display: flex;
  flex-wrap: wrap;
}

.contact-section-child-container-1,
.contact-section-child-container-2 {
  width: 100%;
}

@media screen AND (min-width: 992px) {

  .contact-section-child-container-1,
  .contact-section-child-container-2 {
    width: 49%;
  }
}