p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  color: #fff;
  font-family: 'Montserrat-Light', sans-serif;
}

.footer-containers {
  width: 100%;
  padding-top: 2rem;
  padding-inline: 1rem;
}

@media screen AND (min-width: 992px) {
  .footer-containers {
    width: 33%;
  }
}

@media screen AND (min-width: 1200px) {
  .footer-containers {
    width: 33%;
  }
}