.about-section-container {
  display: flex;
  flex-wrap: wrap;
}

.about-section-child-container {
  width: 100%;
}

.service-container-1 {
  order: 1;
}

.service-container-2 {
  order: 0;
}

.scissor-img {
  width: 40px;
}

.card-width {
  width: 100%;
}

.link-style {
  text-decoration: none;
  color: #fff;
}

.about-section-child-2 {
  display: none;
}

.contact-on-home {
  width: 100% !important;
}

.why-choose-us-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.why-choose-us-card i {
  color: #fff;
  font-size: 48px;
}

.home-get-in-touch-con-1,
.home-get-in-touch-con-2 {
  width: 100%
}

.on-contact-page {
  width: 100% !important;
}

@media screen AND (min-width: 768px) {
  .card-width {
    width: 45%;
  }

  .why-choose-us-card {
    width: 45%
  }

  .home-get-in-touch-con-1,
  .home-get-in-touch-con-2 {
    width: 50%
  }
}

@media screen AND (min-width: 992px) {
  .about-section-child-container {
    width: 50%;
  }

  .service-container-1 {
    order: 0;
  }

  .service-container-2 {
    order: 1;
  }

  .card-width {
    width: 30%;
  }

  .why-choose-us-card {
    width: 30%
  }
}

@media screen AND (min-width: 992px) {
  .about-section-child-2 {
    display: block;
  }
}

@media screen AND (min-width: 1200px) {
  .card-width {
    width: 22%;
  }

  .why-choose-us-card {
    width: 22%
  }
}