.page-container {
  background: linear-gradient(217deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 95.71%),
    linear-gradient(127deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 95.71%),
    linear-gradient(336deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 95.71%);
}

.framer-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.animated-text {
  width: fit-content;
}

.home-sections {
  margin-top: 3rem;
}

.custom-button {
  background-color: rgb(248, 226, 37);
  width: 180px;
}

.btn:disabled {
  color: #fff;
}

.custom-button:hover {
  border-color: rgb(248, 226, 37);
  color: rgb(248, 226, 37);
}

.icon-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-block: 3rem;
}

.page-header {
  display: flex;
  justify-content: center;
  height: 13vh;
}

.header-img {
  object-fit: cover;
}

.mask-div {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(104, 37, 37, 0.4);
  width: 100%;
}

.home-hero {
  background: url(../assets/images/haircut.jpg) no-repeat center top / cover;
  min-height: 570px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home-hero:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #000000c2;
  opacity: .80;
}

.booking-hero {
  background: url(../assets/images/beard.jpg) no-repeat center top / cover;
  min-height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-block: 6rem;
}


.booking-hero:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #000000c2;
  opacity: .80;
}


.breadcumb-area {
  background: url(../assets/images/haircut.jpg) no-repeat center top / cover;
  min-height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.breadcumb-area:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #041827c2;
  opacity: .90;
}

.breadcumb-wrap {
  padding-top: 3px;
}

.breadcumb-wrap h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 13px;
  color: rgb(229, 54, 33);
  position: relative;
  margin-top: 0;
  line-height: 35px;
}

.breadcumb-wrap h5,
.breadcumb-wrap h6,
.breadcumb-wrap p {
  position: relative;
  margin-block: 1.2rem
}

.breadcrum-links {
  position: relative;
  top: 1.5rem;
  display: flex;
  margin-inline: auto;
  width: 200px;
  justify-content: space-between;
}

.breadcrum-links i {
  font-size: 30px;
}

.breadcrum-links i:hover {
  color: rgb(248, 226, 37) !important;
}

.breadcrum-links .address-text:hover {
  color: rgb(248, 226, 37) !important;
}

.breadcumb-wrap button {
  margin-bottom: 13px;
  position: relative;
  margin-top: 0;
}

.video-player {
  height: 40vh;
  width: 100%;
  border-radius: 15px;
}

.about-img {
  width: 100%;
  max-height: 400px;
  border-radius: 15px;
  object-fit: cover;
}

.rating-stars {
  color: yellow;
  margin-left: 2px;
}

.time-ago-text {
  font-size: 0.75rem;
  color: rgb(187, 174, 174)
}

input {
  background-color: #000;
  color: #fff;
}

.service-card-img {
  max-height: 200px;
  object-fit: cover;
}

.service-card {
  transition: all .3s ease-in-out;
}

.service-card:hover {
  transform: scale(1.1);
}

.not-found-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
}

.contact-links {
  text-decoration: none;
  display: flex;
}