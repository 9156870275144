.about-page-section-1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.about-page-child-section,
.about-page-child-section-1,
.about-page-child-section-2 {
  width: 100%;
}

.subsection-animated-container {
  width: 100%
}

@media screen AND (min-width: 992px) {

  .about-page-child-section,
  .about-page-child-section-1,
  .about-page-child-section-2 {
    width: 49%;
  }

  .subsection-animated-container {
    width: 49%;
  }

  .about-page-child-section-1 {
    order: 1;
  }

  .about-page-child-section-2 {
    order: 0;
  }
}